@tailwind base;
@tailwind components;
@tailwind utilities;

.referrals-card-complete {
  background-color: rgba(237, 247, 237, 1) !important;
  border-color: rgba(212, 217, 223, 1) !important;
  color: rgba(0, 0, 0, 0.55) !important;

  .MuiCardHeader-root {
    background-color: rgba(210, 235, 211, 1) !important;
  }
}

.referrals-card-rejected {
  background-color: rgba(254, 236, 235, 1) !important;
  border-color: rgba(212, 217, 223, 1) !important;
  color: rgba(0, 0, 0, 0.55) !important;

  .MuiCardHeader-root {
    background-color: rgba(252, 208, 205, 1) !important;
  }
}

.referrals-card-deceased {
  background-color: rgba(212, 217, 223, 1) !important;
  border-color: rgba(212, 217, 223, 1) !important;
  color: rgba(0, 0, 0, 0.55) !important;
}

@media print {
  html {
    font-size: 65%;
  }
}
