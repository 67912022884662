#service-permission-form {
  .MuiPaper-elevation4 {
    box-shadow: none;
    border: 1px solid #ddd;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .MuiTypography-h6 {
    opacity: 1;
    text-anchor: start;
    font-size: 14px;
    font-weight: 900;
    fill: rgb(55, 61, 63);
  }

  .MUIDataTableBodyCell {
    text-anchor: start;
    font-size: 14px;
    font-weight: 400;
    fill: rgb(55, 61, 63);
  }

  .inner-table {
    .MuiTableCell-root {
      padding: 4px;
      font-size: 0.7rem;
    }

    .MuiTableCell-footer {
      border-style: none;
    }
  }
}
